<template>
  <form @submit.prevent="handleSubmit">
    <input type="email" required placeholder="email" v-model="email" />
    <input type="password" required placeholder="password" v-model="password" />

    <button>Login</button>
    <div class="error">{{ error }}</div>
  </form>
</template>

<script>
import { ref } from "vue";
import useLogin from "../composables/useLogin";

export default {
  name: "Login",

  setup(props, context) {
    const email = ref("");
    const password = ref("");
    const { error, login } = useLogin();

    const handleSubmit = async () => {
      const resp = await login(email.value, password.value);

      if (!error.value) {
        console.log("user is logged in");
        context.emit("login", resp.user);
      }
    };

    return { email, password, handleSubmit, error };
  },
};
</script>

<style>
</style>