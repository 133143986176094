<template>
  <div class="container">
    <Navbar />
    <ChatWindow />
    <NewChatForm />
  </div>
</template>

<script>
import { watch } from "vue";
import Navbar from "../components/Navbar";
import NewChatForm from "../components/NewChatForm";
import ChatWindow from "../components/ChatWindow";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
export default {
  name: "Chatroom",

  setup(props) {
    const { user } = getUser();
    const route = useRouter();

    watch(user, () => {
      console.log("user changed");
      if (!user.value) {
        route.push({ name: "Welcome" });
      }
    });
  },

  components: {
    Navbar,
    NewChatForm,
    ChatWindow,
  },
};
</script>

<style>
</style>