import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAn1FfeKz-6shrChqmh7xPCmMv4dps3yyE",
  authDomain: "vue3fb.firebaseapp.com",
  projectId: "vue3fb",
  storageBucket: "vue3fb.appspot.com",
  messagingSenderId: "552603145388",
  appId: "1:552603145388:web:bfa3f6236646745ef7fb09",
};

// init firebase
firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, timestamp };
