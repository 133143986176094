<template>
  <form @submit.prevent="handleSubmit">
    <input
      type="text"
      required
      placeholder="display name"
      v-model="displayName"
    />

    <input type="email" required placeholder="email" v-model="email" />
    <input type="password" required placeholder="password" v-model="password" />
    <div class="error">{{ error }}</div>
    <button>Sign Up</button>
  </form>
</template>

<script>
import { ref } from "vue";
import useSignup from "../composables/useSignup.js";
export default {
  name: "Signup",

  setup(props, context) {
    const displayName = ref("");
    const email = ref("");
    const password = ref("");

    const { signup, error } = useSignup();

    const handleSubmit = async () => {
      const resp = await signup(email.value, password.value, displayName.value);
      if (!error.value) {
        console.log("user is signed up");
        context.emit("signup", resp.user);
      }
    };

    return { email, password, displayName, handleSubmit, error };
  },
};
</script>

<style>
</style>