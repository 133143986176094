import { ref } from "vue";
import { projectAuth } from "../firebase/config";

const error = ref(null);

const signup = async (email, password, displayName) => {
  error.value = null;

  try {
    const resp = await projectAuth.createUserWithEmailAndPassword(
      email,
      password
    );
    if (!resp) {
      throw new Error("Could not complete signup");
    }

    await resp.user.updateProfile({ displayName });
    error.value = null;
    return resp;
  } catch (err) {
    console.log("error inside composable");
    error.value = err.message;
    throw Error(err.message);
  }
};

const useSignup = () => {
  return { error, signup };
};

export default useSignup;
