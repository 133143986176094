import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";

const getCollection = (collection) => {
  const documents = ref(null);
  const error = ref(null);

  let collectionRef = projectFirestore
    .collection(collection)
    .orderBy("createdAt");

  const unsub = collectionRef.onSnapshot(
    (snapshot) => {
      let results = [];
      console.log("snapshot");
      snapshot.forEach((doc) => {
        doc.data().createdAt &&
          results.push({
            id: doc.id,
            ...doc.data(),
          });
      });
      documents.value = results;
      error.value = null;
    },
    (err) => {
      console.log(err.message);
      documents.value = null;
      error.value = "Could not fetch data";
    }
  );
  //   watchEffect((onInvalidate) => {
  //     //unsub from prev collection when watcher is stopped (component unmounted)

  //     onInvalidate(() => {
  //       unsub();
  //     });
  //   });

  return { documents, error, unsub };
};

export default getCollection;
