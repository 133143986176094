<template>
  <div class="chat-window">
    <div v-if="error" class="error">{{ error }}</div>

    <div ref="cw" class="messages" v-if="documents">
      <div v-for="doc in formatedDocuments" :key="doc.id" class="single">
        <span class="created-at">{{ doc.createdAt }}</span>
        <span class="name">{{ doc.name }}</span>
        <span class="message">{{ doc.message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import getCollection from "../composables/getCollection";
import { formatDistanceToNow } from "date-fns";
import { computed, ref, onMounted, onUpdated, onUnmounted } from "vue";

export default {
  setup(props) {
    const { error, documents, unsub } = getCollection("messages");

    //auto-scroll
    const cw = ref(null);

    const formatedDocuments = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          let time = formatDistanceToNow(doc.createdAt.toDate());
          return {
            ...doc,
            createdAt: time,
          };
        });
      }
    });
    onUnmounted(() => {
      console.log("unmounting called");
      unsub();
    });
    onUpdated(() => {
      cw.value.scrollTop = cw.value.scrollHeight;
    });

    return { error, documents, formatedDocuments, cw };
  },
};
</script>

<style>
.chat-window {
  background: #fafafa;
  padding: 30px 20px;
}
.single {
  margin: 18px 0;
}
.created-at {
  display: block;
  color: #999;
  font-size: 12px;
  margin-bottom: 4px;
}
.name {
  font-weight: bold;
  margin-right: 6px;
}
.messages {
  max-height: 400px;
  overflow: auto;
}
</style>